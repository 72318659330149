import { BrowserRouter, Route } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import NewsEdit from "../pages/NewsEdit";
import PromotionEdit from "../pages/PromotionEdit";
import Splash from "../pages/Splash";

export default function Routes() {
  return (
    <BrowserRouter>
      <Route path="/" exact component={Splash} />
      <Route path="/login" component={Login} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/news/create" component={NewsEdit} />
      <PrivateRoute path="/news/edit/:id" component={NewsEdit} />
      <PrivateRoute path="/promotion/create" component={PromotionEdit} />
      <PrivateRoute path="/promotion/edit/:id" component={PromotionEdit} />
    </BrowserRouter>
  );
}
