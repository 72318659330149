import "./styles.css";

interface ModalConfirmDeleteProps {
  confirmDelete: {
    show: boolean;
  };
  onDelete: (confirmed: boolean) => void;
  notConfirm: () => void;
}

export default function ModalConfirmDelete({
  confirmDelete,
  notConfirm,
  onDelete,
}: ModalConfirmDeleteProps) {
  return (
    <div
      className={`${
        confirmDelete.show ? "confirm-delete" : "confirm-delete hidden"
      }`}
    >
      <div className="content">
        <h3 className="title">Deseja realmente excluir?</h3>
        <div className="actions">
          <button onClick={() => onDelete(true)}>Sim</button>
          <button onClick={() => notConfirm()}>Não</button>
        </div>
      </div>
    </div>
  );
}
