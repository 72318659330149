import { FormEvent, useContext, useState } from "react";
import Lottie from "react-lottie";
import { useHistory } from "react-router-dom";

import logoAnimation from "../../assets/logo-background-animation.json";
import logoImage from "../../assets/logo.png";
import { AlertContext } from "../../contexts/AlertContext";
import { AuthContext } from "../../contexts/AuthContext";

import "./styles.css";

export default function Login() {
  document.title = "Login";

  const history = useHistory();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: logoAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const { login } = useContext(AuthContext);

  const { alert } = useContext(AlertContext);

  async function onLogin(event: FormEvent) {
    event.preventDefault();

    if (isLoading) {
      return;
    }

    if (!validation()) {
      return;
    }

    setIsLoading(true);

    await login(email, password)
      .then((res) => {
        history.push("/dashboard");
      })
      .catch((err) => {
        catchError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function catchError(err: any) {
    console.log({ err });

    switch (err.code) {
      case "auth/wrong-password":
        alert("Senha incorreta", true);
        break;
      default:
        alert("Erro ao fazer login", true);
    }
  }

  function validation() {
    if (email.trim() === "") {
      alert("Email não pode ser vazio", true);
      return false;
    }

    if (password.trim() === "") {
      alert("Senha não pode ser vazia", true);
      return false;
    }

    return true;
  }

  return (
    <main className="login-page">
      <div className="presentation">
        <Lottie options={defaultOptions} height={300} width={300} />
        <img src={logoImage} alt="Óticas Mercadótica" />
      </div>

      <form onSubmit={onLogin}>
        <h1>Entrar</h1>

        <input
          value={email}
          placeholder="Email"
          onChange={(event) => setEmail(event.target.value)}
        />

        <input
          value={password}
          placeholder="Senha"
          type="password"
          onChange={(event) => setPassword(event.target.value)}
        />

        <button disabled={isLoading} type="submit">
          Logar
        </button>
      </form>
    </main>
  );
}
