import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import Loading from "../../components/Loading";

import "./styles.css";

export default function Splash() {
  const history = useHistory();

  document.title = "Iniciando...";

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (currentUser) {
      history.push("/dashboard");
    } else {
      history.push("/login");
    }
  }, [currentUser, history]);

  return (
    <main className="spash-page">
      <Loading />
    </main>
  );
}
