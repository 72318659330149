import { createContext, ReactNode, useState } from "react";
import Alert from "../components/Alert";

interface AlertContextProps {
  alert: (message: string, isError?: boolean) => void;
}

interface AlertProviderProps {
  children: ReactNode;
}

export const AlertContext = createContext<AlertContextProps>({
  alert: function (message: string, isError?: boolean): void {
    throw new Error("Function not implemented.");
  }
});

export function AlertProvider({ children }: AlertProviderProps) {
  const [alertMessage, setAlertMessage] = useState<string>();
  const [alertError, setAlertError] = useState<boolean>();

  function alert(message: string, isError?: boolean) {
    setAlertMessage(message);
    setAlertError(isError === undefined ? true : isError);

    setTimeout(() => {
      setAlertMessage(undefined);
    }, 3000);
  }

  return (
    <AlertContext.Provider value={{ alert }}>
      {alertMessage && <Alert label={alertMessage} isError={alertError} />}
      {children}
    </AlertContext.Provider>
  );
}
