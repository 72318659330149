import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import { NewsContext } from "../../contexts/NewsContext";
import { PromotionContext } from "../../contexts/PromotionsContext";
import { News } from "../../components/News";
import Promotion from "../../components/Promotion";
import { AuthContext } from "../../contexts/AuthContext";
import Loading from "../../components/Loading";

import emptyPosts from "../../assets/empty-posts.svg";

import "./styles.css";

export default function Dashboard() {
  const history = useHistory();

  const { news, isLoadingNews, loadNews } = useContext(NewsContext);
  const { promotions, isLoadingPromotions, loadPromotions } =
    useContext(PromotionContext);

  const { logout } = useContext(AuthContext);

  const [isVisibleAllNews, setIsVisibleAllNews] = useState(false);
  const [isVisibleAllPromotions, setIsVisibleAllPromotions] = useState(false);

  document.title = "Início | Postagens Mercadótica";

  useEffect(() => {
    isVisibleAllNews ? loadNews() : loadNews(6);
    isVisibleAllPromotions ? loadPromotions() : loadPromotions(6);
    // eslint-disable-next-line
  }, []);

  function onCreate(local: string) {
    history.push(`/${local}/create`);
  }

  async function onLogout() {
    await logout();
    history.push("/login");
  }

  function showAllPromotions() {
    loadPromotions();
    setIsVisibleAllPromotions(true);
  }

  function showAllNews() {
    loadNews();
    setIsVisibleAllNews(true);
  }

  return (
    <main className="dashboard-page">
      <header className="page-header">
        <h1>Início</h1>

        <button className="button-logout" onClick={() => onLogout()}>
          Sair
        </button>
      </header>

      <div className="content">
        <div className="posts">
          <h2 className="content-title">
            Notícias
            <button
              className="button-add"
              disabled={isLoadingNews}
              onClick={() => onCreate("news")}
            >
              <AiOutlinePlus className="plus-icon" />
            </button>
          </h2>

          {isLoadingNews ? (
            <Loading />
          ) : news.length > 0 ? (
            <>
              <div className="grid-posts">
                {news.map((news, index) => (
                  <News key={index} news={news} />
                ))}
              </div>
              {!isVisibleAllNews && news.length > 5 && (
                <button className="see-all" onClick={() => showAllNews()}>
                  Ver todas
                </button>
              )}
            </>
          ) : (
            <div className="empty-content">
              <img
                src={emptyPosts}
                alt="Personagem de azul segurando um post que irá publicar"
                className="empty-posts-img"
              />
              <p>Nada por aqui.</p>
            </div>
          )}
        </div>

        <div className="promotion">
          <h2 className="content-title">
            Promoções
            <button
              className="button-add"
              disabled={isLoadingPromotions}
              onClick={() => onCreate("promotion")}
            >
              <AiOutlinePlus className="plus-icon" />
            </button>
          </h2>

          {isLoadingPromotions ? (
            <Loading />
          ) : promotions.length > 0 ? (
            <>
              <div className="grid-posts">
                {promotions.map((promotion, index) => (
                  <Promotion key={index} promotion={promotion} />
                ))}
              </div>
              {!isVisibleAllPromotions && promotions.length > 5 && (
                <button className="see-all" onClick={() => showAllPromotions}>
                  Ver todas
                </button>
              )}
            </>
          ) : (
            <div className="empty-content">
              <img
                src={emptyPosts}
                alt="Personagem de azul segurando um post que irá publicar"
                className="empty-posts-img"
              />
              <p>Nada por aqui.</p>
            </div>
          )}
        </div>
      </div>
    </main>
  );
}
