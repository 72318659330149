import { FormEvent, useContext, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Loading from "../../components/Loading";
import { useHistory } from "react-router-dom";
import { BsCode, BsCodeSlash } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import EditContentBar from "../../components/EditContentBar";
import { PromotionContext } from "../../contexts/PromotionsContext";
import toBase64 from "../../utils/toBase64";
import formatHTML from "../../utils/formatHTML";
import { AlertContext } from "../../contexts/AlertContext";

import "./styles.css";

export default function PromotionEdit() {
  //Puxa o historico de navegacao
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const { pathname } = useLocation();

  const { getPromotion, editPromotion, createPromotion } =
    useContext(PromotionContext);

  const { alert } = useContext(AlertContext);

  const [mode, setMode] = useState<"create" | "edit">("create");

  //Parametros dos valores dos campos
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("<p><br/></p>");
  const [file, setFile] = useState<File>();
  const [imageURL, setImageURL] = useState("");
  const [starting, setStarting] = useState("");
  const [finishing, setFinishing] = useState("");

  //Parametros para indicar error ao campos
  const [titleError, setTitleError] = useState(false);
  const [contentError, setContentError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [imageURLError, setImageURLError] = useState(false);
  const [finishingError, setFinishingError] = useState(false);
  const [startingError, setStartingError] = useState(false);

  const [loading, setLoading] = useState(false);

  const [isContentSetCode, setIsContentSetCode] = useState(false);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    formatNow();
    // eslint-disable-next-line
  }, [isContentSetCode]);

  //Funcao para criar um post
  async function onSave(event: FormEvent) {
    //Previne o carregamento da pagina
    event.preventDefault();

    //Verifica se os campos estao incorretos
    const fieldsVerify = validation();

    if (!fieldsVerify.allRight) {
      alert("Erro: " + fieldsVerify.error);
      return;
    }

    const content = document.getElementById("content-editable")?.innerHTML;

    if (!content) {
      alert("Conteúdo não pode ser vazio");
      return;
    }

    let imageData = "";

    if (file) {
      imageData = await toBase64(file)
        .then((res) => String(res))
        .catch((err) => "");
    } else {
      imageData = imageURL;
    }

    let response;

    setLoading(true);
    if (mode === "create") {
      response = await createPromotion(
        title,
        content,
        imageData,
        new Date(starting + "GMT-3"),
        new Date(finishing + "GMT-3")
      );
    } else {
      response = await editPromotion(
        id,
        title,
        content,
        imageData,
        new Date(starting + "GMT-3"),
        new Date(finishing + "GMT-3")
      );
    }
    setLoading(false);

    if (response) {
      mode === "create"
        ? alert("Postagem feita com sucesso", false)
        : alert("Edição feita com sucesso", false);
      history.push("/dashboard");
    }
  }

  //Funcao para cancelar a criacao de um post
  function onCancel(event: FormEvent) {
    //Previne o carregamento da pagina
    event.preventDefault();

    //Volta para tela principla
    history.push("/dashboard");
  }

  async function loadData() {
    const isEditing = pathname.includes("/promotion/edit/");

    setMode(isEditing ? "edit" : "create");

    document.title = `${
      mode === "create" ? "Criar" : "Editar"
    } promoção | Postagem Mercadótica`;

    if (isEditing) {
      setLoading(true);

      const promotionToEdit = await getPromotion(id);

      setLoading(false);

      if (promotionToEdit !== undefined) {
        const startingAtDate = new Date(
          promotionToEdit.startingAt.seconds * 1000
        )
          .toISOString()
          .slice(0, 10);
        const finishingAtDate = new Date(
          promotionToEdit.finishingAt.seconds * 1000
        )
          .toISOString()
          .slice(0, 10);

        const { title, content, imageURL } = promotionToEdit;

        setTitle(title);
        setContent(content);
        setImageURL(imageURL);
        setStarting(startingAtDate);
        setFinishing(finishingAtDate);
      } else {
        alert("Promoção não encontrada");

        history.push("/dashboard");
      }
    }
  }

  //Funcao que verifica se os campos foram devidamente preenchidos
  function validation() {
    //Verifica se o campo esta vazio
    if (title.trim() === "") {
      //Indica um erro que sera mostrado no campo
      setTitleError(true);
      document.getElementsByTagName("main")[0].scrollTo(0, 0);
      //Para de indicar o erro apos 3000 milissegundos
      setTimeout(() => setTitleError(false), 3000);
      return { allRight: false, error: "título vazio" };
    }

    //pega o conteudo do contente-editable
    const content = document.getElementById("content-editable")?.innerHTML;

    if (content === "<p><br></p>" || content === "") {
      document.getElementsByTagName("main")[0].scrollTo(0, 235);
      setContentError(true);
      setTimeout(() => setContentError(false), 3000);
      return { allRight: false, error: "conteúdo vazio" };
    }

    //Verifica se existe arquivo e se eh menor que 100mb
    if (!file && imageURL === "") {
      if (imageURL === "") {
        //Indica um erro que sera mostrado no campo
        setImageURLError(true);
        //Para de indicar o erro apos 3000 milissegundos
        setTimeout(() => setImageURLError(false), 3000);
        return { allRight: false, error: "url da imagem vazia" };
      }
      //Indica um erro que sera mostrado no campo
      setFileError(true);
      //Para de indicar o erro apos 3000 milissegundos
      setTimeout(() => setFileError(false), 3000);
      return { allRight: false, error: "sem arquivo de imagem" };
    }

    if (file && file.size > 500000) {
      //Indica um erro que sera mostrado no campo
      setFileError(true);
      //Para de indicar o erro apos 3000 milissegundos
      setTimeout(() => setFileError(false), 3000);
      return {
        allRight: false,
        error: ` excedido - ${file.size}`,
      };
    }

    if (starting === "") {
      //Indica um erro que sera mostrado no campo
      setStartingError(true);
      //Para de indicar o erro apos 3000 milissegundos
      setTimeout(() => setStartingError(false), 3000);
      return { allRight: false, error: "sem data de início" };
    }

    if (finishing === "") {
      //Indica um erro que sera mostrado no campo
      setFinishingError(true);
      //Para de indicar o erro apos 3000 milissegundos
      setTimeout(() => setFinishingError(false), 3000);
      return { allRight: false, error: "sem data de término" };
    }
    return { allRight: true };
  }

  function setContentCode() {
    const newContent = document.getElementById("content-editable")?.innerHTML;

    if (newContent) {
      setContent(newContent);
    }

    setIsContentSetCode(!isContentSetCode);
  }

  function setImage(data: string | File) {
    if (typeof data === "object") {
      setFile(data);
      setImageURL("");
    } else {
      setFile(undefined);
      setImageURL(data);
    }
  }

  function formatNow() {
    if (isContentSetCode) {
      setContent(formatHTML(content));
    }
  }

  return (
    <main className="edit-page">
      {loading && (
        <div id="loading-modal">
          <Loading />
        </div>
      )}

      <h1 className="title">{mode === "edit" ? "Editar" : "Criar"} promoção</h1>

      <form onSubmit={onSave} onReset={onCancel}>
        <div className="input-box">
          <label>Título</label>
          {mode === "edit" ? (
            <input
              value={title}
              readOnly
              disabled
              className={titleError ? "error" : ""}
            />
          ) : (
            <input
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              className={titleError ? "error" : ""}
            />
          )}
        </div>

        <div className="input-box">
          <div className="title-button-box">
            <label>Conteúdo</label>
            <button
              type="button"
              className="set-content"
              onClick={() => setContentCode()}
            >
              {isContentSetCode ? <BsCodeSlash /> : <BsCode />}
            </button>
          </div>
          {!isContentSetCode && <EditContentBar />}
          {!isContentSetCode ? (
            <div
              className={
                contentError ? "content-editable error" : "content-editable"
              }
              id="content-editable"
              contentEditable
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          ) : (
            <textarea
              value={content}
              spellCheck={false}
              onChange={(event) => setContent(event.target.value)}
              className={
                contentError ? "content-editable error" : "content-editable"
              }
            />
          )}
        </div>

        <div className="input-box image">
          <div>
            <label>Upload de capa</label>
            <div className="select-file">
              <label htmlFor="input-file">
                {file
                  ? file.name.slice(0, 25) + "... ." + file.name.split(".")[1]
                  : "Selecione um arquivo"}
              </label>
              <MdClose
                className="clean-selection"
                onClick={() => setFile(undefined)}
              />
            </div>
            <input
              type="file"
              id="input-file"
              accept="image/png, image/jpeg"
              size={100000}
              className={fileError ? "error" : ""}
              onChange={(event) =>
                event.target.files && event.target.files[0]
                  ? setImage(event.target.files[0])
                  : null
              }
            />
          </div>
          <span>ou</span>
          <div>
            <label>URL da capa</label>
            <input
              className={imageURLError ? "error" : ""}
              value={imageURL}
              onChange={(event) => setImage(event.target.value)}
            />
          </div>
        </div>

        <div className="input-box dates">
          <div>
            <label>Data de início</label>
            <input
              type="date"
              value={starting}
              onChange={(event) => setStarting(event.target.value)}
              className={startingError ? "error" : ""}
            />
          </div>
          <div>
            <label>Data de término</label>
            <input
              type="date"
              value={finishing}
              min={starting}
              onChange={(event) => setFinishing(event.target.value)}
              className={finishingError ? "error" : ""}
            />
          </div>
        </div>

        <button className="button-submit" type="submit">
          Salvar
        </button>

        <button className="button-cancel" type="reset">
          Cancelar
        </button>
      </form>
    </main>
  );
}
