import "./styles.css";

interface AlertProps {
  label: string;
  isError?: boolean;
}

export default function Alert({ label, isError }: AlertProps) {
  return (
    <div className={`alert-component ${isError && "error"}`}>
      <div className="alert-box">
        <label>{label}</label>
      </div>
    </div>
  );
}
