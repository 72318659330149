import { useContext, useState } from "react";
import { HiOutlineTrash, HiPencil } from "react-icons/hi";
import { useHistory } from "react-router-dom";
import { PromotionContext } from "../../contexts/PromotionsContext";
import PromotionProps from "../../interfaces/PromotionProps";
import ModalConfirmDelete from "../ModalConfirmDelete";

interface PromotionComponentProps {
  promotion: PromotionProps;
}

export default function Promotion({ promotion }: PromotionComponentProps) {
  const history = useHistory();

  const { setIsLoadingPromotions, deletePromotion } =
    useContext(PromotionContext);

  const [confirmDelete, setConfirmDelete] = useState({
    show: false,
  });

  //Recebe o id da noticia ou promocao, um booleano para saber se eh um post, um booleano para saber passou pela confirmacao
  async function onDelete(confirmed: boolean) {
    if (!confirmed) {
      //confirmacao de exclusao
      setConfirmDelete({ show: true });
      return;
    }

    setConfirmDelete({ show: false });

    setIsLoadingPromotions(true);

    await deletePromotion(promotion.id).finally(() => {
      setIsLoadingPromotions(false);
    });
  }

  function notConfirm() {
    setConfirmDelete({ show: false });
  }

  function onEdit(id: string) {
    history.push(`/promotion/edit/${id}`);
  }

  return (
    <div className="box-item" key={promotion.id}>
      <ModalConfirmDelete
        confirmDelete={confirmDelete}
        notConfirm={notConfirm}
        onDelete={onDelete}
      />

      <img src={promotion.imageURL} alt={promotion.title} />
      <div className="text-item">
        <h3>{promotion.title}</h3>
        <p>
          {`Criado em: ${Intl.DateTimeFormat("pt-br").format(
            new Date(promotion.createdAt.seconds * 1000)
          )}`}
        </p>
        {promotion.editedAt && (
          <p>
            {`Editado em: ${Intl.DateTimeFormat("pt-br").format(
              new Date(promotion.editedAt.seconds * 1000)
            )}`}
          </p>
        )}
      </div>
      <div className="box-buttons">
        <button onClick={() => onEdit(promotion.id)}>
          <HiPencil size={20} />
        </button>
        <button onClick={() => onDelete(false)}>
          <HiOutlineTrash size={20} />
        </button>
      </div>
    </div>
  );
}
