import { FormEvent, useContext, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Loading from "../../components/Loading";
import { useHistory } from "react-router-dom";
import { BsCheck, BsCode, BsCodeSlash } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { NewsContext } from "../../contexts/NewsContext";
import EditContentBar from "../../components/EditContentBar";
import toBase64 from "../../utils/toBase64";
import formatHTML from "../../utils/formatHTML";
import { AlertContext } from "../../contexts/AlertContext";
import "./styles.css";
import { storage } from "../../services/firebase";

export default function NewsEdit() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();
  const { getNews, editNews, createNews } = useContext(NewsContext);
  const { alert } = useContext(AlertContext);

  const [mode, setMode] = useState<"create" | "edit">("create");
  const [slug, setSlug] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("<p><br/></p>");
  const [createdAt, setCreatedAt] = useState("");
  const [file, setFile] = useState<File>();
  const [imageURL, setImageURL] = useState("");
  const [category1, setCategory1] = useState(false);
  const [category2, setCategory2] = useState(false);
  const [category3, setCategory3] = useState(false);

  const [titleError, setTitleError] = useState(false);
  const [contentError, setContentError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [imageURLError, setImageURLError] = useState(false);
  const [createdAtError, setCreatedAtError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isContentSetCode, setIsContentSetCode] = useState(false);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    formatNow();
    // eslint-disable-next-line
  }, [isContentSetCode]);

  const storageRef = storage.ref("news");

  async function onSave(event: FormEvent) {
    event.preventDefault();

    const fieldsVerify = validation();

    if (!fieldsVerify.allRight) {
      alert("Erro: " + fieldsVerify.error);
      return;
    }

    const content = document.getElementById("content-editable")?.innerHTML;

    if (!content) {
      alert("Conteúdo não pode ser vazio");
      return;
    }

    let imageData = "";

    if (file) {
      const result = await toBase64(file);
      if (typeof result === "string") {
        imageData = result;
        setImageURL(`data:image/jpeg;base64,${imageData}`);
      } else {
        alert("Erro ao processar a imagem");
        return;
      }
    } else {
      imageData = imageURL;
    }

    const categories = getCategories();
    let response;

    const parser = new DOMParser();
    const htmlContent = parser.parseFromString(content, "text/html");
    const imgTag = htmlContent.querySelector("img");

    if (imgTag && !imgTag.getAttribute("src")?.startsWith("data:image")) {
      imgTag.setAttribute("src", `data:image/jpeg;base64,${imageData}`);
    }

    setLoading(true);
    if (mode === "create") {
      response = await createNews(
        title,
        htmlContent.documentElement.innerHTML,
        imageData,
        createdAt,
        categories
      );
    } else {
      response = await editNews(
        slug,
        htmlContent.documentElement.innerHTML,
        imageData,
        createdAt,
        categories
      );
    }
    setLoading(false);

    if (response) {
      mode === "create"
        ? alert("Postagem feita com sucesso", false)
        : alert("Edição feita com sucesso", false);
      history.push("/dashboard");
    }
  }

  function getCategories() {
    const categories: number[] = [];

    [category1, category2, category3].map((item, index) => {
      if (item) {
        categories.push(index + 1);
      }
      return item;
    });

    return categories;
  }

  function onCancel(event: FormEvent) {
    event.preventDefault();
    history.push("/dashboard");
  }

  async function loadData() {
    const isEditing = pathname.includes("/news/edit/");
    setMode(isEditing ? "edit" : "create");

    document.title = `${mode === "create" ? "Criar" : "Editar"
      } notícia | Postagem Mercadótica`;

    if (isEditing) {
      setLoading(true);
      const newsToEdit = await getNews(id);
      setLoading(false);

      if (newsToEdit !== undefined) {
        const createdAt = new Date(newsToEdit.createdAt.seconds * 1000)
          .toISOString()
          .slice(0, 10);

        setSlug(newsToEdit.slug);
        setTitle(newsToEdit.title);

        const parser = new DOMParser();
        const htmlContent = parser.parseFromString(newsToEdit.content, "text/html");
        const imgTag = htmlContent.querySelector("img");

        if (imgTag) {
          const imgSrc = imgTag.getAttribute("src");
          if (imgSrc && imgSrc.startsWith("data:image")) {
            setImageURL(imgSrc);
          } else {
            setImageURL("");
          }
        }

        setContent(htmlContent.documentElement.innerHTML);
        setCreatedAt(createdAt);
        setCategory1(newsToEdit.categories.includes(1));
        setCategory2(newsToEdit.categories.includes(2));
        setCategory3(newsToEdit.categories.includes(3));
      } else {
        alert("Notícia não encontrada");
        history.push("/dashboard");
      }
    }
  }

  function validation() {
    if (title.trim() === "") {
      setTitleError(true);
      document.getElementsByTagName("main")[0].scrollTo(0, 0);
      setTimeout(() => setTitleError(false), 3000);
      return { allRight: false, error: "título vazio" };
    }

    const content = document.getElementById("content-editable")?.innerHTML;

    if (content === "<p><br></p>" || content === "") {
      document.getElementsByTagName("main")[0].scrollTo(0, 235);
      setContentError(true);
      setTimeout(() => setContentError(false), 3000);
      return { allRight: false, error: "conteúdo vazio" };
    }

    if (!file && imageURL === "") {
      if (imageURL === "") {
        setImageURLError(true);
        setTimeout(() => setImageURLError(false), 3000);
        return { allRight: false, error: "url da imagem vazia" };
      }
      setFileError(true);
      setTimeout(() => setFileError(false), 3000);
      return { allRight: false, error: "sem arquivo de imagem" };
    }

    if (file && file.size > 5000000) {
      setFileError(true);
      setTimeout(() => setFileError(false), 3000);
      return {
        allRight: false,
        error: `tamanho da imagem excedido - ${file.size}`,
      };
    }

    if (createdAt === "") {
      setCreatedAtError(true);
      setTimeout(() => setCreatedAtError(false), 3000);
      return { allRight: false, error: "sem data" };
    }

    if (!category1 && !category2 && !category3) {
      setCategoryError(true);
      setTimeout(() => setCategoryError(false), 3000);
      return { allRight: false, error: "sem categorias" };
    }

    return { allRight: true };
  }

  function setContentCode() {
    const newContent = document.getElementById("content-editable")?.innerHTML;

    if (newContent) {
      setContent(newContent);
    }

    setIsContentSetCode(!isContentSetCode);
  }

  function setImage(data: string | File) {
    if (typeof data === "object") {
      toBase64(data).then((result) => {
        if (typeof result === "string") {
          setFile(data);
          setImageURL(`data:image/jpeg;base64,${result}`);
        } else {
          alert("Erro ao processar a imagem");
        }
      });
    } else {
      if (!data.startsWith("data:image")) {
        setImageURL(`data:image/jpeg;base64,${data}`);
      } else {
        setImageURL(data);
      }
      setFile(undefined);
    }
  }

  function formatNow() {
    if (isContentSetCode) {
      setContent(formatHTML(content));
    }
  }

  return (
    <main className="edit-page">
      {loading && (
        <div id="loading-modal">
          <Loading />
        </div>
      )}

      <h1 className="title">{mode === "edit" ? "Editar" : "Criar"} notícia</h1>

      <form onSubmit={onSave} onReset={onCancel}>
        <div className="input-box">
          <label>Título</label>
          {mode === "edit" ? (
            <input
              value={title}
              readOnly
              disabled
              className={titleError ? "error" : ""}
            />
          ) : (
            <input
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              className={titleError ? "error" : ""}
            />
          )}
        </div>

        <div className="input-box">
          <div className="title-button-box">
            <label>Conteúdo</label>
            <button
              type="button"
              className="set-content"
              onClick={() => setContentCode()}
            >
              {isContentSetCode ? <BsCodeSlash /> : <BsCode />}
            </button>
          </div>
          {!isContentSetCode && <EditContentBar />}
          {!isContentSetCode ? (
            <div
              className={
                contentError ? "content-editable error" : "content-editable"
              }
              id="content-editable"
              contentEditable
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          ) : (
            <textarea
              value={content}
              spellCheck={false}
              onChange={(event) => setContent(event.target.value)}
              className={
                contentError ? "content-editable error" : "content-editable"
              }
            />
          )}
        </div>

        <div className="input-box image">
          <div>
            <label>Upload de capa</label>
            <div className="select-file">
              <label htmlFor="input-file">
                {file
                  ? file.name.slice(0, 25) + "... ." + file.name.split(".")[1]
                  : "Selecione um arquivo"}
              </label>
              <MdClose
                className="clean-selection"
                onClick={() => setFile(undefined)}
              />
            </div>
            <input
              type="file"
              id="input-file"
              accept="image/png, image/jpeg"
              size={100000}
              className={fileError ? "error" : ""}
              onChange={(event) =>
                event.target.files && event.target.files[0]
                  ? setImage(event.target.files[0])
                  : null
              }
            />
          </div>
          <span>ou</span>
          <div>
            <label>URL da capa</label>
            <input
              className={imageURLError ? "error" : ""}
              value={imageURL}
              onChange={(event) => setImage(event.target.value)}
            />
          </div>
        </div>

        <div className="input-box">
          <label>Data</label>
          <input
            type="date"
            value={createdAt}
            onChange={(event) => setCreatedAt(event.target.value)}
            className={createdAtError ? "error" : ""}
          />
        </div>

        <div className="input-box">
          <label>Categoria</label>
          <div
            className={
              categoryError ? "category-options error" : "category-options"
            }
          >
            <button
              type="button"
              className={`${category1 ? "option checked" : "option"}`}
              onClick={() => setCategory1(!category1)}
            >
              <BsCheck />
              <label>Saúde Visual</label>
            </button>
            <button
              type="button"
              className={`${category2 ? "option checked" : "option"}`}
              onClick={() => setCategory2(!category2)}
            >
              <BsCheck />
              <label>Empreendedorismo</label>
            </button>
            <button
              type="button"
              className={`${category3 ? "option checked" : "option"}`}
              onClick={() => setCategory3(!category3)}
            >
              <BsCheck />
              <label>Institucional</label>
            </button>
          </div>
        </div>

        <button className="button-submit" type="submit">
          Salvar
        </button>

        <button className="button-cancel" type="reset">
          Cancelar
        </button>
      </form>
    </main>
  );
}
