import { AlertProvider } from "../contexts/AlertContext";
import { AuthProvider } from "../contexts/AuthContext";
import { NewsProvider } from "../contexts/NewsContext";
import { PromotionsProvider } from "../contexts/PromotionsContext";
import Routes from "../routes";

import "../styles/global.css";

function App() {
  return (
    <AlertProvider>
      <AuthProvider>
        <NewsProvider>
          <PromotionsProvider>
            <Routes />
          </PromotionsProvider>
        </NewsProvider>
      </AuthProvider>
    </AlertProvider>
  );
}

export default App;
