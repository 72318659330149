import { createContext, ReactNode, SetStateAction, useContext, useState } from "react";
import PromotionProps from "../interfaces/PromotionProps";
import functions from "../services/functions";
import { AuthContext } from "./AuthContext";

interface PromotionContextProps {
  promotions: PromotionProps[];
  isLoadingPromotions: boolean;
  setIsLoadingPromotions: React.Dispatch<React.SetStateAction<boolean>>;
  loadPromotions: (limit?: number) => void;
  getPromotion: (id: string) => Promise<PromotionProps | undefined>;
  createPromotion: (
    title: string,
    content: string,
    imageData: string,
    startingAt: Date,
    finishingAt: Date
  ) => Promise<boolean>;
  editPromotion: (
    id: string,
    title: string,
    content: string,
    imageData: string,
    startingAt: Date,
    finishingAt: Date
  ) => Promise<boolean>;
  deletePromotion: (id: string) => Promise<boolean>;
}

interface PromotionsProviderProps {
  children: ReactNode;
}

export const PromotionContext = createContext<PromotionContextProps>({
  promotions: [],
  isLoadingPromotions: false,
  setIsLoadingPromotions: function (value: SetStateAction<boolean>): void {
    throw new Error("Function not implemented.");
  },
  loadPromotions: function (limit?: number): void {
    throw new Error("Function not implemented.");
  },
  getPromotion: function (id: string): Promise<PromotionProps | undefined> {
    throw new Error("Function not implemented.");
  },
  createPromotion: function (title: string, content: string, imageData: string, startingAt: Date, finishingAt: Date): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  editPromotion: function (id: string, title: string, content: string, imageData: string, startingAt: Date, finishingAt: Date): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  deletePromotion: function (id: string): Promise<boolean> {
    throw new Error("Function not implemented.");
  }
});

export function PromotionsProvider({ children }: PromotionsProviderProps) {
  const [promotions, setPromotions] = useState<PromotionProps[]>([]);

  const { currentUser } = useContext(AuthContext);

  const [isLoadingPromotions, setIsLoadingPromotions] = useState(false);

  function loadPromotions(limit?: number) {
    setIsLoadingPromotions(true);

    functions
      .get(`/promotions${limit ? `?number=${limit}` : ""}`)
      .then((res) => {
        setPromotions(res.data.promotions);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoadingPromotions(false);
      });
  }

  async function getPromotion(id: string) {
    var response: PromotionProps | undefined;

    setIsLoadingPromotions(true);

    await functions
      .get(`/promotion/${id}`)
      .then((res) => {
        console.log(res.data);
        response = res.data.promotion;
      })
      .catch((err) => {
        response = undefined;
      })
      .finally(() => {
        setIsLoadingPromotions(false);
      });

    console.log(response);

    return response;
  }

  async function createPromotion(
    title: string,
    content: string,
    imageData: string,
    startingAt: Date,
    finishingAt: Date
  ) {
    setIsLoadingPromotions(true);

    const token = await currentUser?.getIdToken();

    const res = await functions
      .post(
        "/promotions",
        {
          title,
          content,
          imageData,
          startingAt,
          finishingAt,
        },
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => true)
      .catch((err) => {
        console.log({ err });
        return false;
      });

    setIsLoadingPromotions(false);

    return res;
  }

  async function editPromotion(
    id: string,
    title: string,
    content: string,
    imageData: string,
    startingAt: Date,
    finishingAt: Date
  ) {
    setIsLoadingPromotions(true);

    const token = await currentUser?.getIdToken();

    const res = await functions
      .put(
        `/promotion/${id}`,
        {
          title,
          content,
          imageData,
          startingAt,
          finishingAt,
        },
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => true)
      .catch((err) => {
        console.log({ err });
        return false;
      });

    setIsLoadingPromotions(false);

    return res;
  }

  async function deletePromotion(id: string) {
    const token = await currentUser?.getIdToken();

    const res = await functions
      .delete(`/promotion/${id}`, {
        headers: { authorization: "Bearer " + token },
      })
      .then((res) => {
        loadPromotions();
        return true;
      })
      .catch((err) => {
        console.log({ err });
        return false;
      });

    return res;
  }

  return (
    <PromotionContext.Provider
      value={{
        promotions,
        isLoadingPromotions,
        setIsLoadingPromotions,
        loadPromotions,
        getPromotion,
        createPromotion,
        editPromotion,
        deletePromotion,
      }}
    >
      {children}
    </PromotionContext.Provider>
  );
}
