import { createContext, ReactNode, useEffect, useState } from "react";
import { auth } from "../services/firebase";
import app from "firebase/app";

interface AuthContextProps {
  currentUser: app.User | undefined;
  login: (email: string, password: string) => Promise<any>;
  logout: () => any;
  isLoading: boolean;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext<AuthContextProps>({
  currentUser: undefined,
  login: function (email: string, password: string): Promise<any> {
    throw new Error("Function not implemented.");
  },
  logout: function () {
    throw new Error("Function not implemented.");
  },
  isLoading: false
});

export function AuthProvider({ children }: AuthProviderProps) {
  const [currentUser, setCurrentUser] = useState<app.User | undefined>();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(undefined);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  async function login(email: string, password: string) {
    const userCredential = await auth.signInWithEmailAndPassword(
      email,
      password
    );

    if (userCredential.user) {
      setCurrentUser(userCredential.user);
    } else {
      setCurrentUser(undefined);
    }
  }

  function logout() {
    return auth.signOut();
  }

  return (
    <AuthContext.Provider value={{ currentUser, login, logout, isLoading }}>
      {!isLoading && children}
    </AuthContext.Provider>
  );
}
