import { createContext, ReactNode, SetStateAction, useContext, useState } from "react";
import NewsProps from "../interfaces/NewsProps";
import functions from "../services/functions";
import { AlertContext } from "./AlertContext";
import { AuthContext } from "./AuthContext";

interface NewsContextProps {
  news: NewsProps[];
  isLoadingNews: boolean;
  setIsLoadingNews: React.Dispatch<React.SetStateAction<boolean>>;
  loadNews: (limit?: number) => void;
  getNews: (string: string) => Promise<NewsProps | undefined>;
  createNews: (
    title: string,
    content: string,
    imageData: string,
    createdAt: string,
    categories: number[]
  ) => Promise<boolean>;
  editNews: (
    slug: string,
    content: string,
    imageData: string,
    createdAt: string,
    categories: number[]
  ) => Promise<boolean>;
  deleteNews: (slug: string) => Promise<boolean>;
}

interface NewsProviderProps {
  children: ReactNode;
}

export const NewsContext = createContext<NewsContextProps>({
  news: [],
  isLoadingNews: false,
  setIsLoadingNews: function (value: SetStateAction<boolean>): void {
    throw new Error("Function not implemented.");
  },
  loadNews: function (limit?: number): void {
    throw new Error("Function not implemented.");
  },
  getNews: function (string: string): Promise<NewsProps | undefined> {
    throw new Error("Function not implemented.");
  },
  createNews: function (title: string, content: string, imageData: string, createdAt: string, categories: number[]): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  editNews: function (slug: string, content: string, imageData: string, createdAt: string, categories: number[]): Promise<boolean> {
    throw new Error("Function not implemented.");
  },
  deleteNews: function (slug: string): Promise<boolean> {
    throw new Error("Function not implemented.");
  }
});

export function NewsProvider({ children }: NewsProviderProps) {
  const [news, setNews] = useState<NewsProps[]>([]);

  const { currentUser } = useContext(AuthContext);

  const { alert } = useContext(AlertContext);

  const [isLoadingNews, setIsLoadingNews] = useState(false);

  function loadNews(limit?: number) {
    setIsLoadingNews(true);

    functions
      .get(`/news${limit ? `?number=${limit}` : ""}`)
      .then((res) => {
        setNews(res.data.news);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoadingNews(false);
      });
  }

  async function getNews(slug: string) {
    var response: NewsProps | undefined;

    setIsLoadingNews(true);

    await functions
      .get(`/news/${slug}`)
      .then((res) => {
        response = res.data.news;
      })
      .catch((err) => {
        response = undefined;
      })
      .finally(() => {
        setIsLoadingNews(false);
      });

    return response;
  }

  async function createNews(
    title: string,
    content: string,
    imageData: string,
    createdAt: string,
    categories: number[]
  ) {
    setIsLoadingNews(true);

    const token = await currentUser?.getIdToken();

    const res = await functions
      .post(
        "/news",
        {
          title,
          content,
          imageData,
          createdAt: createdAt + " 00:00",
          categories,
        },
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => true)
      .catch((err) => {
        console.log({ err });
        if (err.isAxiosError) {
          if (err.response && err.response.data) {
            alert(err.response.data.error);
          } else {
            alert(err.message);
          }
        } else {
          alert("Erro inesperado");
        }
        return false;
      });

    setIsLoadingNews(false);

    return res;
  }

  async function editNews(
    slug: string,
    content: string,
    imageData: string,
    createdAt: string,
    categories: number[]
  ) {
    setIsLoadingNews(true);

    const token = await currentUser?.getIdToken();

    const res = await functions
      .put(
        `/news/${slug}`,
        {
          content,
          imageData,
          createdAt: createdAt + " 00:00 GMT-0300",
          categories,
        },
        { headers: { authorization: "Bearer " + token } }
      )
      .then((res) => true)
      .catch((err) => {
        console.log({ err });
        if (err.isAxiosError) {
          alert(err.message);
        } else {
          alert("Erro inesperado");
        }
        return false;
      })
      .finally(() => {
        setIsLoadingNews(false);
      });

    return res;
  }

  async function deleteNews(slug: string) {
    const token = await currentUser?.getIdToken();

    const res = await functions
      .delete(`/news/${slug}`, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        loadNews();
        return true;
      })
      .catch((err) => false);

    loadNews();

    return res;
  }

  return (
    <NewsContext.Provider
      value={{
        news,
        isLoadingNews,
        setIsLoadingNews,
        loadNews,
        getNews,
        editNews,
        createNews,
        deleteNews,
      }}
    >
      {children}
    </NewsContext.Provider>
  );
}
