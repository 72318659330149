import { useContext, useState } from "react";
import { HiOutlineTrash, HiPencil } from "react-icons/hi";
import { useHistory } from "react-router-dom";
import { NewsContext } from "../../contexts/NewsContext";
import NewsProps from "../../interfaces/NewsProps";
import ModalConfirmDelete from "../ModalConfirmDelete";

interface NewsComponentProps {
  news: NewsProps;
}

export function News({ news }: NewsComponentProps) {
  const history = useHistory();

  const { setIsLoadingNews, deleteNews } = useContext(NewsContext);

  const [confirmDelete, setConfirmDelete] = useState({
    show: false,
  });

  function onEdit(slug: string) {
    history.push(`/news/edit/${slug}`);
  }

  //Recebe um booleano para saber passou pela confirmacao
  async function onDelete(confirmed: boolean) {
    if (!confirmed) {
      //confirmacao de exclusao
      setConfirmDelete({ show: true });
      return;
    }

    setIsLoadingNews(true);

    setConfirmDelete({ show: false });

    await deleteNews(news.slug).finally(() => {
      setIsLoadingNews(false);
    });
  }

  function notConfirm() {
    setConfirmDelete({
      show: false,
    });
  }

  return (
    <div className="box-item" key={news.slug}>
      <ModalConfirmDelete
        confirmDelete={confirmDelete}
        onDelete={onDelete}
        notConfirm={notConfirm}
      />

      <img src={news.imageURL} alt={news.title} />
      <div className="text-item">
        <h3>{news.title}</h3>
        <p>
          {`Criado em: ${Intl.DateTimeFormat("pt-br").format(
            new Date(news.createdAt.seconds * 1000)
          )}`}
        </p>
        {news.editedAt && (
          <p>
            {`Editado em: ${Intl.DateTimeFormat("pt-br").format(
              new Date(news.editedAt.seconds * 1000)
            )}`}
          </p>
        )}
      </div>
      <div className="box-buttons">
        <button onClick={() => onEdit(news.slug)}>
          <HiPencil size={20} />
        </button>
        <button onClick={() => onDelete(false)}>
          <HiOutlineTrash size={20} />
        </button>
      </div>
    </div>
  );
}
