import { useEffect, useState } from "react";
import {
  AiFillYoutube,
  AiOutlineFontSize,
  AiOutlineOrderedList,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { BiLink, BiUnlink } from "react-icons/bi";
import {
  BsJustify,
  BsJustifyLeft,
  BsJustifyRight,
  BsTextCenter,
  BsTextIndentLeft,
  BsTextIndentRight,
  BsFillImageFill,
} from "react-icons/bs";
import { VscPaintcan } from "react-icons/vsc";
import toBase64 from "../../utils/toBase64";

import "./styles.css";

export default function EditContentBar() {
  const [fontColor, setFontColor] = useState("#000000");
  const [highlightColor, setHighlightColor] = useState("#ffffff");
  const [fontColorInHighlight, setFontColorInHighlight] = useState("#000000");

  const [isShowImageModal, setIsShowImageModal] = useState(false);
  const [imageSelected, setImageSelected] = useState<string>("");
  const [fileSelected, setFileSelected] = useState<File>();
  const [alternativeText, setAlteranativeText] = useState("");
  const [imageWidth, setImageWidth] = useState("");
  const [imageHeight, setImageHeight] = useState("");
  const [imageURL, setImageURL] = useState("");

  const [isShowVideoModal, setIsShowVideoModal] = useState(false);
  const [videoURL, setVideoURL] = useState("");
  const [videoWidth, setVideoWidth] = useState("640");
  const [videoHeight, setVideoHeight] = useState("380");

  useEffect(() => {
    changeImage();
    // eslint-disable-next-line
  }, [fileSelected]);

  useEffect(() => {
    if (imageSelected !== "") {
      setImageURL("");
    }
  }, [imageSelected]);

  useEffect(() => {
    if (imageURL !== "") {
      setImageSelected("");
    }
  }, [imageURL]);

  async function changeImage() {
    if (fileSelected) {
      const image = String(await toBase64(fileSelected));
      setImageSelected(image);
    }
  }

  function insertImage() {
    if (
      (imageSelected === "" && imageURL === "") ||
      alternativeText === "" ||
      imageWidth.trim() === "" ||
      imageHeight.trim() === ""
    ) {
      alert("Preencha todos os campos");
      return;
    }

    setIsShowImageModal(false);

    execCommand("insertHTML");

    cleanImageData();
  }

  function cleanImageData() {
    setImageSelected("");
    setAlteranativeText("");
    setImageWidth("");
    setImageHeight("");
    setIsShowImageModal(false);
  }

  function cleanVideoData() {
    setVideoURL("");
    setIsShowVideoModal(false);
  }

  async function execCommand(command: string, value?: string) {
    const content = document.getElementById("content-editable");

    content?.focus();

    if (value === "link") {
      const linkURL = prompt("Enter a URL:", "http://") || undefined;
      document.execCommand(command, false, linkURL);
      return;
    }

    if (command === "insertHTML") {
      var image;

      if (fileSelected) {
        const file = String(await toBase64(fileSelected));

        image = `<img src="${file}" width="${imageWidth}" heigth="${imageHeight}" alt="${alternativeText}" />`;
      } else {
        image = `<img src="${imageURL}" width="${imageWidth}" heigth="${imageHeight}" alt="${alternativeText}" />`;
      }

      document.execCommand(command, false, image);
      return;
    }

    if (command === "insertVideo") {
      var cod = videoURL
        .split("/")
        .map((item, index) => {
          if (item.includes("watch")) {
            var url = item.replace("watch?v=", "");
            return url.split("&")[0];
          }
          if (item.includes("embed")) {
            return videoURL.split("/")[index + 1];
          }
          return null;
        })
        .filter((item) => item)[0];

      if (!cod) {
        alert("URL inválida");
        return;
      }

      var iframe = `<iframe 
        src="https://www.youtube.com/embed/${cod}" 
        frameborder="0" 
        allowfullscreen="allowfullscreen" 
        width="${videoWidth}"
        height="${videoHeight}"
      />`;

      document.execCommand("insertHTML", false, iframe);
      cleanVideoData();
    }

    if (command === "fontName" || command === "fontSize") {
      console.log(command, value);
      if (!value) return;
      document.execCommand(command, true, value);
      return;
    }

    if (value && value[0] === "#") {
      document.execCommand(command, true, value);

      if (command === "foreColor") {
        setFontColor(value);
      } else {
        setHighlightColor(value);

        var valNumerico = parseInt(value.replace("#", ""), 16);
        var dest = valNumerico > 7829367 ? "#000000" : "#FFFFFF";
        setFontColorInHighlight(dest);
      }

      return;
    }

    document.execCommand(command);
  }

  return (
    <>
      {/* Image modal */}
      {isShowImageModal && (
        <div className="image-modal">
          <div className="image-modal-visible">
            <h2>Inserir imagem</h2>

            <img
              src={imageSelected || imageURL}
              alt={imageSelected === "" ? "sem imagem" : "imagem selecionada"}
            />

            <input
              className="select-image"
              type="file"
              accept="image/png, image/jpeg"
              onChange={(event) =>
                event.target.files &&
                event.target.files[0] &&
                setFileSelected(event.target.files[0])
              }
            />
          
            {!fileSelected && (
              
              <>
             
              <h5>*Somente imagens com resoluções 1024x1365 ou menores*</h5>
            <a href="https://www.iloveimg.com/pt/redimensionar-imagem/png-tamanho#resize-options,pixels"
             target="_blank" rel="noopener noreferrer" style={{color:"blue"}}>Redimensione aqui</a>
              <br></br>
                <h3>URL da imagem</h3>
                <input
                  className="input-image-url"
                  value={imageURL}
                  onChange={(event) => setImageURL(event.target.value)}
                />
              </>
            )}

            <h3>Texto alternativo</h3>
            <input
              className="input-alteranative-text"
              placeholder="Descreva a imagem"
              value={alternativeText}
              onChange={(event) => setAlteranativeText(event.target.value)}
            />

            <h3>Tamanho da imagem</h3>
            <div className="image-size-inputs">
              <input
                type="number"
                placeholder="Largura"
                value={imageWidth}
                onChange={(event) => setImageWidth(event.target.value)}
              />
              <span>X</span>
              <input
                type="number"
                placeholder="Altura"
                value={imageHeight}
                onChange={(event) => setImageHeight(event.target.value)}
              />
            </div>

            <div className="buttons-box">
              <button type="button" onClick={() => insertImage()}>
                Inserir
              </button>
              <button type="button" onClick={() => cleanImageData()}>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}

      {isShowVideoModal && (
        <div className="video-modal">
          <div className="video-modal-visible">
            <h2>Inserir vídeo</h2>

            <h3>URL da vídeo</h3>
            <input
              className="input-video-url"
              value={videoURL}
              onChange={(event) => setVideoURL(event.target.value)}
            />

            <h3>Tamanho do vídeo</h3>
            <div className="video-size-inputs">
              <input
                type="number"
                placeholder="Largura"
                value={videoWidth}
                onChange={(event) => setVideoWidth(event.target.value)}
              />
              <span>X</span>
              <input
                type="number"
                placeholder="Altura"
                value={videoHeight}
                onChange={(event) => setVideoHeight(event.target.value)}
              />
            </div>

            <div className="buttons-box">
              <button type="button" onClick={() => execCommand("insertVideo")}>
                Inserir
              </button>
              <button type="button" onClick={() => cleanVideoData()}>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="actions-bar">
        <button type="button" onClick={() => execCommand("bold")}>
          <b>N</b>
        </button>
        <button type="button" onClick={() => execCommand("italic")}>
          <i>I</i>
        </button>
        <button type="button" onClick={() => execCommand("underline")}>
          <u>S</u>
        </button>

        <button
          type="button"
          onClick={() => execCommand("insertUnorderedList")}
        >
          <AiOutlineUnorderedList />
        </button>
        <button type="button" onClick={() => execCommand("insertOrderedList")}>
          <AiOutlineOrderedList />
        </button>

        <button type="button" onClick={() => execCommand("justifyLeft")}>
          <BsJustifyLeft />
        </button>
        <button type="button" onClick={() => execCommand("justifyCenter")}>
          <BsTextCenter />
        </button>
        <button type="button" onClick={() => execCommand("justifyRight")}>
          <BsJustifyRight />
        </button>
        <button type="button" onClick={() => execCommand("justifyFull")}>
          <BsJustify />
        </button>
      </div>

      <div className="actions-bar">
        <button type="button" onClick={() => execCommand("createLink", "link")}>
          <BiLink />
        </button>
        <button type="button" onClick={() => execCommand("unlink")}>
          <BiUnlink />
        </button>

        <button type="button" onClick={() => execCommand("indent")}>
          <BsTextIndentLeft />
        </button>
        <button type="button" onClick={() => execCommand("outdent")}>
          <BsTextIndentRight />
        </button>

        <button type="button" onClick={() => setIsShowImageModal(true)}>
          <BsFillImageFill />
        </button>

        <button type="button" onClick={() => setIsShowVideoModal(true)}>
          <AiFillYoutube />
        </button>

        <div className="input-font-size">
          <AiOutlineFontSize className="icon-font-size" />
          <select
            onChange={(event) => execCommand("fontSize", event.target.value)}
            defaultValue={3}
          >
            {[0, 1, 2, 3, 4, 5, 6, 7].map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>

        <div className="input-color-box">
          <input
            type="color"
            className="font-color-input"
            name="color-input"
            onChange={(event) => execCommand("foreColor", event.target.value)}
          />
          <label
            htmlFor="color-input"
            className="pseudo-color-input"
            style={{ color: fontColor }}
          >
            A
            <span
              style={{
                background: fontColor,
              }}
              className="span-color"
            ></span>
          </label>
        </div>

        <div className="input-color-box">
          <input
            type="color"
            className="font-color-input"
            name="color-input"
            onChange={(event) => execCommand("hiliteColor", event.target.value)}
          />
          <label
            htmlFor="color-input"
            className="pseudo-color-input"
            style={{
              background: highlightColor,
              color: fontColorInHighlight,
            }}
          >
            <VscPaintcan />
          </label>
        </div>
      </div>
    </>
  );
}
